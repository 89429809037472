import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function RegisterPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const register = () => {
    axios.post('/api/register', {
      username: username,
      password: password,
    })
    .then((response) => {
      console.log(response);
      navigate('/login'); // Redirect to the LoginPage
    })
    .catch((error) => {
      console.log(error.response);
      setError(error.response.data.error);
    });
  };

  return (
    <div className="register">
      <h2>Registration</h2>
      {error && <p>{error}</p>}
      <label>Name:</label>
      <input type="text" onChange={(e) => {setUsername(e.target.value);}} />
      <input type="password" onChange={(e) => {setPassword(e.target.value);}} />
      <button onClick={register}>Register</button>
    </div>
  );
}

export default RegisterPage;

