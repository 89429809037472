import React, { useState } from 'react';
import axios from 'axios';

const ContactUsPage = () => {
  const [hearAbout, setHearAbout] = useState('');
  const [socialMediaOption, setSocialMediaOption] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Send the form data to the backend API
      const response = await axios.post('/api/submit-form', {
        name: event.target.name.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        hearAbout: hearAbout,
        socialMediaOption: hearAbout === 'Social Media' ? socialMediaOption : '',
        message: event.target.message.value,
      });

      console.log(response.data.message); // Success message from the backend
      // You can add a success message to the user if needed

      // Clear the form fields after successful submission
      event.target.reset();
    } catch (error) {
      console.error('Error submitting form:', error.message); // Error message from the backend
      // You can add an error message to the user if needed
    }
  };

  const handleHearAboutChange = (event) => {
    setHearAbout(event.target.value);
    setSocialMediaOption(''); // Reset the social media option when the hearAbout option changes
  };

  return (
    <div>
      <div
        style={{
          marginTop: '20px',
          backgroundColor: 'rgb(131, 207, 202)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: '#f7f7f3',
          padding: 0,
          margin: 0
        }}
      >
        <h1 style={{ margin: '0.5' }}>Request a Consultation</h1>
        <p style={{
          fontSize: '20px',
          lineHeight: '1.5',
          margin: '20px auto',
          textAlign: 'center',
          maxWidth: '800px',
          color: 'white',
        }}>
          Thank you for inquiring about our services. Reflections of You, by Amy takes pride in creating a design
          around the personality of the client or office. For more information or to schedule your initial consult,
          please fill out our contact form and someone will be in touch shortly.
        </p>
        <div style={{ display: 'flex', alignItems: 'center' }}></div>
        <form onSubmit={handleSubmit} style={{ width: '70%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
            <label htmlFor="name" style={{ fontSize: '20px' }}>*Name:</label>
            <input type="text" id="name" name="name" required style={{ width: '100%', padding: '10px' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
            <label htmlFor="email" style={{ fontSize: '20px' }}>*Email Address:</label>
            <input type="email" id="email" name="email" required style={{ width: '100%', padding: '10px' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
            <label htmlFor="phone" style={{ fontSize: '20px' }}>*Phone Number:</label>
            <input type="tel" id="phone" name="phone" required style={{ width: '100%', padding: '10px' }} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
            <label htmlFor="hearAbout" style={{ fontSize: '20px' }}>*How Did You Hear About Us?</label>
            <select
              id="hearAbout"
              name="hearAbout"
              value={hearAbout}
              onChange={handleHearAboutChange}
              required
              style={{ width: '100%', padding: '10px' }}
            >
              <option value="">Select an option</option>
              <option value="Real Estate Agent Referral">Real Estate Agent Referral</option>
              <option value="I Know One of Your Designers:">I Know One of Your Designers: (Please tell us who in the message field.)</option>
              <option value="Google">Google</option>
              <option value="Social Media">Social Media</option>
              <option value="Other">Other</option>
            </select>
          </div>

          {hearAbout === 'Social Media' && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
              <label htmlFor="socialMediaOption" style={{ fontSize: '20px' }}>Social Media Platform:</label>
              <select
                id="socialMediaOption"
                name="socialMediaOption"
                value={socialMediaOption}
                onChange={(e) => setSocialMediaOption(e.target.value)}
                required
                style={{ width: '100%', padding: '10px' }}
              >
                <option value="">Select a platform</option>
                <option value="Facebook">Facebook</option>
                <option value="Instagram">Instagram</option>
                <option value="Twitter">Twitter</option>
                <option value="Other">Other (Please specify in the message section)</option>
              </select>
            </div>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10px' }}>
            <label htmlFor="message" style={{ fontSize: '20px' }}>Message:</label>
            <textarea id="message" name="message" rows="5" style={{ width: '100%', padding: '10px' }}></textarea>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button
              type="submit"
              style={{
                display: 'block',
                textAlign: 'center',
                padding: '15px 25px',
                backgroundColor: 'rgb(131, 207, 202)',
                color: '#f7f7f3',
                borderRadius: '5px',
                fontSize: '20px',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                maxWidth: '250px',
                cursor: 'pointer',
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = 'rgb(18, 174, 174)';
                e.target.style.transform = 'translateY(-3px)';
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = 'rgb(131, 207, 202)';
                e.target.style.transform = 'translateY(0)';
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <div
        style={{
          backgroundColor: 'rgb(91, 209, 215)',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: '50px',
          padding: '0 20px',
          color: '#f7f7f3',
          fontSize: '25px',
        }}
      ></div>
    </div>
  );
};

export default ContactUsPage;


















