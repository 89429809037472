import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { marked } from 'marked';
import '../components/AboutUsPage.css';
import { useAuth } from '../context/authContext';

const AboutUsPage = () => {
    const { isLoggedIn } = useAuth();
    const [aboutUsImages, setAboutUsImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [editingImage, setEditingImage] = useState(null);
    const [editFormData, setEditFormData] = useState({ image_path: '', description: '' });

    useEffect(() => {
        const fetchAboutUsImages = async () => {
            try {
                const response = await axios.get('/api/aboutus');
                setAboutUsImages(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching about us images:', error);
                setIsLoading(false);
            }
        };

        fetchAboutUsImages();
    }, []);

    const startEditing = (image) => {
        setEditingImage(image);
        setEditFormData({ image_path: image.image_path, description: image.description });
    };

    const handleEditChange = (event) => {
        const { name, value } = event.target;
        setEditFormData({ ...editFormData, [name]: value });
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('description', editFormData.description);
            if (event.target.image.files[0]) {
                formData.append('image', event.target.image.files[0]);
            }

            await axios.put(`/api/aboutus-images/${editingImage.id}`, formData);
        } catch (error) {
            console.error('Error updating image:', error);
        }
        setEditingImage(null);
    };

    if (isLoggedIn && editingImage) {
        return (
            <form onSubmit={handleEditSubmit}>
                <input
                    type="file"
                    name="image"
                    accept="image/*"
                />
                <textarea
                    className="edit-description-textarea"
                    name="description"
                    value={editFormData.description}
                    onChange={handleEditChange}
                />
                <button type="submit">Save Changes</button>
                <button onClick={() => setEditingImage(null)}>Cancel</button>
            </form>
        );
    }

    const renderEditButton = (image) => {
        return isLoggedIn ? (
            <button onClick={() => startEditing(image)}>
                Edit
            </button>
        ) : null;
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div
                style={{
                    backgroundColor: 'rgb(131, 207, 202)',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                    alignItems: 'center',
                    height: '50px',
                    padding: '0 20px',
                    color: '#f7f7f3',
                    fontSize: '30px',
                }}
            >
                <div style={{ width: '100%', textAlign: 'center' }}>About Us</div>
            </div>

            <div>
                <div className="about-us-container">
                    {aboutUsImages.map((image, index) => {
                        return (
                            <div key={image.id} className="about-us-item">
                                <img src={image.image_path} alt={`About us ${index + 1}`} />
                                {isLoggedIn && renderEditButton(image)}
                                <div
                                    dangerouslySetInnerHTML={{ __html: marked(image.description) }}
                                    className="about-us-description"
                                />
                            </div>
                        );
                    })}
                </div>
                <div className="schedule-section">
                    <p>Schedule Your Initial Consult Today!</p>
                    <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
                        <button className="schedule-button">
                            Schedule Today!
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default AboutUsPage;
