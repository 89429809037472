import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import '../components/fade-in.css';
import '../components/HomePage.css';
import { useAuth } from '../context/authContext';
import axios from 'axios';
import storeImage from '../assets/storefront.png';

const HomePage = () => {
  const { isLoggedIn, logout } = useAuth();
  const [isLoaded] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
    }
    fetchBackgroundImage();
  }, [isLoggedIn]);

  const handleUpload = useCallback(() => {
    axios.get('http://amywagnerdesigns.com/api/background-image/')
      .then((response) => {
        const imageUrl = `/uploads/${response.data.image_path}`;
        setBackgroundImage(imageUrl);
      })
      .catch((error) => {
        console.log(error);
      });

    if (selectedImage) {
      const formData = new FormData();
      formData.append('image', selectedImage);
      axios
        .post('http://amywagnerdesigns.com/uploadReplaceBackgroundImage', formData)
        .then(() => {
          fetchBackgroundImage();
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
        });
    }
  }, [selectedImage]);

  const fetchBackgroundImage = () => {
    axios
      .get('http://amywagnerdesigns.com/api/background-image')
      .then((response) => {
        if (response.data && response.data.image_path) {
          const basePath = 'http://amywagnerdesigns.com/uploads';
          const imageUrl = `${basePath}/${response.data.image_path}`;
          setBackgroundImage(imageUrl);
        } else {
          console.error('Invalid response format:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching background image:', error);
      });
  };

  const fileInputRef = useRef(null);

  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };

  const handleLogout = () => {
    logout();
  };

  // Swipeable handler (left swipe triggers the store)
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      window.location.href = "https://reflectionsofyoubyamy.com/";  // Trigger on left swipe
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,  // Allows swipe detection with the mouse (for testing on desktop)
  });

  // Open full screen image page
  const openFullScreenImage = () => {
    navigate('/fullscreen-image', { state: { backgroundImage } });
  };

  return (
    <div {...handlers}>  {/* Add swipe handlers to the parent div */}
      {isLoggedIn && (
        <div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileInputChange}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          <button onClick={handleImageUpload}>Upload Photo</button>
        </div>
      )}

      <div className="homepage-container" onClick={openFullScreenImage}>
        <img src={backgroundImage} alt="Background" className="homepage-image" />
      </div>

      <div className="schedule-section">
        <p>Schedule Your Initial Consult Today!</p>
        <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
          <button className="schedule-button">Schedule Today!</button>
        </Link>

        {/* Store Image for Mobile - visible only on screens below 768px */}
        <div className="store-image-container">
          <p className="store-callout">
            Shop our store for the decor that reflects your style!
          </p>
          <p className="store-subtext">
            Discover hand-selected pieces that bring elegance and charm to every space.
            Tap Below OR Swipe Right to Start Shopping Now! 🛍️
          </p>
          <a href="https://reflectionsofyoubyamy.com/" target="_blank" rel="noopener noreferrer">
            <img src={storeImage} alt="Visit Our Store" className="store-image" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;



