import React, { useState, useEffect } from 'react';
import '../Nav.css';
import logo from '../assets/Amy_LOGO.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { ReactComponent as XTwitterLogo } from '../assets/twitterx.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import storefrontImage from '../assets/storefront.png';

function GlobalNavbar() {
  const { isLoggedIn, logout } = useAuth();
  const [hovered, setHovered] = useState(false); // State to manage hover
  const [showImage, setShowImage] = useState(false); // State to keep the image visible for 5 seconds
  const [imageHoverActive, setImageHoverActive] = useState(false); // State to activate hover rules for the image

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    if (hovered) {
      setShowImage(true);

      // Activate hover rules for the image after 5 seconds
      const timer = setTimeout(() => {
        setImageHoverActive(true);
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      setShowImage(false);
      setImageHoverActive(false);
    }
  }, [hovered]);

  return (
    <div>
      <div className="teal-navbar">
        <div className="teal-navbar-left">
          <a href="tel:502-384-3660" className="maroon-link">
            <FontAwesomeIcon icon={faPhone} /> 502-384-3660
          </a>
          <a href="mailto:info.reflectionsofyou@gmail.com" className="maroon-link">
            <FontAwesomeIcon icon={faEnvelope} /> info.reflectionsofyou@gmail.com
          </a>
          <div className="social-links">
            <a href="https://www.facebook.com/reflctionsofyoubyamy?mibextid=ZbWKwL" className="social-link">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <span className="social-link-space"></span>
            <a href="https://twitter.com/amywagnerdesign" className="social-link">
              <XTwitterLogo className="x-icon" />
            </a>
            <span className="social-link-space"></span>
            <a href="https://instagram.com/amywagnerdesigns?igshid=MzRlODBiNWFlZA==" className="social-link">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>

        {/* {isLoggedIn ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <Link to="/login">My Account</Link>
        )} */}
      </div>

      <div className="cream-navbar">
        <div className="cream-navbar-left">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </div>
        <div className="cream-navbar-right">
          <Link to="/">
            <button className="cream-navbar-link big-link">Home</button>
          </Link>

          <div
            className="shop-link-container"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <a
              href="https://reflectionsofyoubyamy.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                }}
              >
                {/* Button */}
                <button
                  className="shop-link"
                  style={{
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    color: '#fff',
                    backgroundColor: 'rgb(18, 174, 174)',
                    padding: '12px 20px',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    boxShadow: hovered ? '0 5px 15px rgba(0, 0, 0, 0.2)' : 'none',
                    transform: hovered ? 'scale(1.1)' : 'scale(1)',
                    transition: 'all 0.3s ease',
                    position: 'center',
                  }}
                >
                  Shop our New Store!
                </button>

                {/* Show Storefront Image for at least 5 seconds */}
                {showImage && (
                  <div
                    className={`storefront-image ${imageHoverActive ? 'hover-active' : ''}`}
                    style={{
                      position: 'absolute',
                      top: '60px',
                      left: '0',
                      width: '300px',
                      padding: '10px',
                      backgroundColor: '#f7f7f3',
                      borderRadius: '5px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      zIndex: '1000',
                      transition: 'transform 0.3s ease',
                      transform: hovered || imageHoverActive ? 'scale(1.1)' : 'scale(1)',
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    <img src={storefrontImage} alt="Storefront" style={{ width: '100%' }} />
                    <p
                      style={{
                        fontSize: '14px',
                        color: 'rgb(119, 207, 208)',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      Welcome to Reflections of You by Amy!
                    </p>
                  </div>
                )}
              </div>
            </a>
          </div>
          <div className="dropdown">
            <button className="cream-navbar-link big-link dropdown-toggle">Our Services</button>
            <div className="dropdown-menu">
              <Link to="/realEstateServices" className="dropdown-item">
                Real Estate Services
              </Link>
              <Link to="/interiordesign" className="dropdown-item">
                Interior Design
              </Link>
            </div>
          </div>

          <Link to="/aboutus">
            <button className="cream-navbar-link big-link">About Us</button>
          </Link>
          <Link to="/contact">
            <button className="cream-navbar-link big-link">Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GlobalNavbar;