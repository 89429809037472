import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../components/FullScreenImagePage.css';  // Ensure the CSS file is imported

const FullScreenImagePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const backgroundImage = location.state?.backgroundImage || '';

    const handleClose = () => {
        navigate('/');  // Go back to the homepage
    };

    return (
        <div className="fullscreen-container">
            <button className="close-button" onClick={handleClose}>X</button>
            <img src={backgroundImage} alt="Full Background" className="fullscreen-image" />
        </div>
    );
};

export default FullScreenImagePage;

