import React from 'react';
import './ImageModal.css'; // Ensure the CSS path is correct

const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null; // Don't render the modal if there's no image

    return (
        <div className="image-modal-backdrop" onClick={onClose}>
            <div className="image-modal-content" onClick={e => e.stopPropagation()}>
                {/* Image now scales within these constraints */}
                <img src={imageUrl} alt="Expanded View" />
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ImageModal;



