import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import '../components/VideoToursPage.css';

const VideoToursPage = () => {
    const [videos, setVideos] = useState([]);
    const [videoFile, setVideoFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        fetchVideos();
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await axios.get('http://amywagnerdesigns.com/api/video-tours');
            // Log the response data to see what you're getting back
            console.log("Received videos data:", response.data);

            // Log each video URL for debugging
            response.data.videos.forEach(video => {
                console.log('Video URL:', video.video_url);
            });

            setVideos(response.data.videos);
        } catch (error) {
            console.error("Error fetching videos:", error);
        }
    };


    const handleVideoUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('video', videoFile);
        formData.append('title', title);
        formData.append('description', description);

        try {
            await axios.post('http://amywagnerdesigns.com/api/upload-video', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            fetchVideos(); // Refresh list after upload
        } catch (error) {
            console.error("Error uploading video:", error);
        }
    };

    const deleteVideo = async (id) => {
        if (window.confirm("Are you sure you want to delete this video?")) {
            await axios.delete(`http://amywagnerdesigns.com/api/video-tours/${id}`);
            fetchVideos();
        }
    };

    return (
        <div className="video-tours-page">
            <div className="design-banner">Video Gallery</div>
            {isLoggedIn && (
                <form onSubmit={handleVideoUpload} className="video-upload-form">
                    <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" required />
                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description"></textarea>
                    <input type="file" onChange={(e) => setVideoFile(e.target.files[0])} accept="video/*" required />
                    <button type="submit">Upload Video</button>
                </form>
            )}
            <div className="videos-list">
                {videos.map((video) => {
                    return (
                        <div key={video.id} className="video-item">
                            <h2>{video.title}</h2>
                            <p>{video.description}</p>
                            <video width="320" height="240" controls>
                                <source src={video.video_url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {isLoggedIn && (
                                <button onClick={() => deleteVideo(video.id)}>Delete Video</button>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="schedule-section">
                <p>Schedule Your Initial Consult Today!</p>
                <Link to="/contact" style={{ textDecoration: 'none', width: '100%' }}>
                    <button className="schedule-button">
                        Schedule Today!
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default VideoToursPage;

