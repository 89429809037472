import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Set the initial state of isLoggedIn based on the presence of a token in localStorage
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check if a valid token exists in localStorage when the component mounts
    const token = localStorage.getItem('token');
    if (token) {
      // Token exists, set setIsLoggedIn to true
      setIsLoggedIn(true);
    } else {
      // Token doesn't exist, handle it accordingly (e.g., show an error message)
      console.error('No token found in localStorage');
    }
  }, []);

  useEffect(() => {
    // This useEffect will run whenever isLoggedIn changes
    console.log("isLoggedIn has changed:", isLoggedIn);
  }, [isLoggedIn]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};




