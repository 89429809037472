import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/authContext'; // Import useAuth

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    try {
      const response = await axios.post('http://amywagnerdesigns.com/api/login', {
        username: username,
        password: password,
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        login(response.data.token);
        navigate('/');
      } else {
        console.error('No token received in response:', response.data);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin(); // Call the correct login function
    }
  };

  return (
    <div>
      <h1>Login Page</h1>
      <form>
        <input
          type="text"
          placeholder="Username"
          onChange={(e) => setUsername(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button type="button" onClick={handleLogin}>
          Login
        </button>
      </form>
      <p>
        {/* Don't have an account? <Link to="/register">Register</Link> */}
      </p>
    </div>
  );
};

export default LoginPage;



