import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import ImageModal from '../components/ImageModal';
import '../components/DesignPage.css';

const GalleryPage = () => {
    const [projects, setProjects] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null); // State for the selected image
    const { isLoggedIn } = useAuth();

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await axios.get('/api/projects/Gallery');
            console.log("Received projects data:", response.data);
            setProjects(response.data.projects);
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    const deleteProject = async (projectId) => {
        if (window.confirm("Are you sure you want to delete this project?")) {
            try {
                await axios.delete(`/api/projects/${projectId}`);
                fetchProjects(); // Refresh the list after deletion
            } catch (error) {
                console.error("Error deleting project:", error);
            }
        }
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    return (
        <div className="design-page"> {/* Updated class name */}
            <div className="design-banner">Gallery</div>
            {isLoggedIn && (
                <Link to="/create-project">Create New Project</Link>
            )}
            <div className="design-project-container"> {/* Adjust as per your CSS */}
                {projects.map(project => (
                    <div key={project.project_id} className="design-project"> {/* Updated class name */}
                        <h2>{project.project_name}</h2>
                        <p>{project.project_description}</p>
                        <div className="images-container"> {/* Ensure this matches your CSS */}
                            {project.images && project.images.map((image, index) => (
                                <div className="image-wrapper" key={index} onClick={() => handleImageClick(`${image}`)}>
                                    <img src={`${image}`} alt={`${project.project_name} ${index + 1}`} />
                                </div>
                            ))}
                        </div>
                        {isLoggedIn && (
                            <>
                                <button className="design-button" onClick={() => deleteProject(project.project_id)}>Delete</button>
                                <Link className="design-button" to={`/edit-project/${project.project_id}`}>Edit</Link>
                            </>
                        )}
                    </div>
                ))}
            </div>
            {selectedImage && <ImageModal imageUrl={selectedImage} onClose={() => setSelectedImage(null)} />}
        </div>
    );
};

export default GalleryPage;