import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalNavbar from './components/GlobalNavbar';
import HomePage from './pages/HomePage';
import ContactUsPage from './pages/ContactUsPage';
import HomeRemodelingPage from './pages/HomeRemodelingPage';
import InteriorDesignPage from './pages/InteriorDesignPage';
import VideoToursPage from './pages/VideoToursPage';
import GalleryPage from './pages/GalleryPage';
import AboutUsPage from './pages/AboutUsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import { AuthProvider } from './context/authContext';
import RealEstatePage from './pages/RealEstatePage';
import ProjectForm from './components/ProjectForm';
import FullScreenImagePage from './pages/FullScreenImagePage';
import StorePage from './pages/StorePage';

const App = () => {
  return (
    <Router>
      <div>
        <AuthProvider>
          <GlobalNavbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/realestateservices" element={<RealEstatePage />} />
            <Route path="/homeremodeling" element={<HomeRemodelingPage />} />
            <Route path="/interiordesign" element={<InteriorDesignPage />} />
            <Route path="/videotours" element={<VideoToursPage />} />
            <Route path="/gallery" element={<GalleryPage/>} />
            <Route path="/create-project" element={<ProjectForm />} />
            <Route path="/store" element={<StorePage />} />
            <Route path="/fullscreen-image" element={<FullScreenImagePage />} /> 
          </Routes>
        </AuthProvider>
      </div>
    </Router>
  );
};

export default App;


